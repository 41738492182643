
import { Component, Vue } from "vue-property-decorator";
import Answer from "@/components/QA/Answer.vue";
@Component({
  components: { Answer },
})
export default class Order extends Vue {
  orderList: { QTwo: string; Ans: string[] }[] = [
    {
      QTwo: "我一定要透過APP下單嗎?",
      Ans: [
        "是,除了透過師虎來了APP下單,個人用戶不接受任何形式包含電話.FB等等,為了確保您在體驗師虎修繕服務,全程都可以擁有一對一客服專案管理師為您掌管專案進度。",
      ],
    },
    {
      QTwo: "只能用信用卡付款嗎?",
      Ans: [
        "新台幣五萬元以下小額修繕我們只提供線上刷卡,若超過新台幣五萬我們會開放權限給您,可選擇採用匯款或信用卡支付方式。",
      ],
    },
    {
      QTwo: "我可以事先客製估價嗎?",
      Ans: [
        "平台在每一個工項都有提供一個過去累積該項目最常發生的金額給您參考,你可以事先查看,但無法在立案前為您專屬報價,實際報價仍要以師傅至現場勘查後為依據。",
      ],
    },
    {
      QTwo: "為何要支付300元派遣費?",
      Ans: [
        "每一個師傅至您府上勘查至少要花費1-2小時,包含通勤時間,油資,人力成本,專業技術以及平台的維運,若您不同意師傅報價,我們仍然會支付師傅車馬費用,讓他獲得應該有的報酬。",
      ],
    },
    {
      QTwo: "300元派遣費可以折抵施工費用嗎?",
      Ans: ["可以,當您同意師傅報價單,此筆派遣費是可以全額抵扣。"],
    },
    {
      QTwo: "300元派遣費可以退嗎?",
      Ans: [
        "不行,我們是在媒合師傅成功後才會收取300元派遣費,代表您已經預約師傅的時間。",
      ],
    },
    {
      QTwo: "平台會開立發票嗎?",
      Ans: ["會。"],
    },
    {
      QTwo: "如有報帳需求,可以開立統一編號嗎?",
      Ans: ["可以,當您在下單前填寫訂單資訊頁面可以輸入統一編號。"],
    },
    {
      QTwo: "平台流程哪裡可以看到?",
      Ans: ["從下單流程可以看到。"],
    },
    {
      QTwo: "我可以先連絡客服嗎?",
      Ans: [
        "在您成立訂單後(支付300元派遣費用)我們可以擁有一對一客服專案管理師。",
      ],
    },
  ];
  questionObj?: { QTwo: string; Ans: string[] };
  // viewWidth!: number;
  isAnswer = false;

  //hook
  // mounted(): void {
  //   this.viewWidth = window.innerWidth;
  //   window.onresize = () => {
  //     this.viewWidth = window.innerWidth;
  //   };
  // }
  //methods
  getQuestion(questionItem: {QTwo:string; Ans:string[]}): void {
    // let index = e.target.getAttribute("index");
    // this.questionObj = this.orderList[index];
    this.questionObj = questionItem;

    this.isAnswer = true;

    if(window.innerWidth > 768){
        window.scroll({
          top: 300,
        });
    }

    // if (this.viewWidth > 768) {
    //   document.documentElement.scrollTop = 300;
    // } else {
    //   document.documentElement.scrollTop = 0;
    // }
  }
}
